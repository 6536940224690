import { Button,CircularProgress,Grid,makeStyles,Tooltip } from '@material-ui/core';
import React,{
  useContext,
  useEffect,useRef,useState,
} from 'react';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import { getTargetFile } from '../../storage';
import WaveSurfer from '../../components/WaveSurfer';
import sentry from '../../sentry';
import Context from '../../Context';

const useStyles = makeStyles(theme => ({
  mic: {
    borderRadius: `50%`,
    padding: `5px`,
    minWidth: `auto`,

  },
  micIcon: { [theme.breakpoints.down(`sm`)]: { fontSize: `40px` } },
  loadingMic: {
    maxWidth: `24px`,
    maxHeight: `24px`,
  },
  label: { textAlign: `center` },
}));

export default function Prerecorded({ sentence,setDuration,setPlayed,preloadSentence,meta }) {
  const classes = useStyles();

  // Target loading
  const [targetLoading,setTargetLoading] = useState();

  const { loading,user } = useContext(Context);
  const [sound,setSound] = useState();
  const [player,setPlayer] = useState();
  const [playing,setPlaying] = useState();
  const [ready,setReady] = useState(false);
  const preloadData = useRef(undefined);
  const preloadedSentence = useRef();

  const isFemale = meta ? meta.gender === `Female` : false;

  useEffect(() => {
    if (preloadData.current !== undefined) return;
    getTargetFile(preloadSentence,isFemale).then(s => {
      preloadData.current = s;
      preloadedSentence.current = preloadSentence;
    }).catch(() => {
      preloadData.current = null;
    });
  },[preloadSentence]);

  const loadSound = e => {
    setPlayed(false);
    if (sentence === preloadedSentence.current) {
      if (preloadData.current === null) {
        setSound(preloadData.current);
        preloadData.current = undefined;
        setPlayed(true);
        return;
      }
      if (preloadData.current !== undefined) {
        setSound(preloadData.current);
        preloadData.current = undefined;
        return;
      }
    }
    preloadData.current = undefined;
    setTargetLoading(true);
    getTargetFile(sentence,isFemale).then(s => {
      setSound(s);
      setTargetLoading(false);
    }).catch(err => {
      sentry(err);
      setSound(null);
      setTargetLoading(false);
      setPlayed(true);
      setDuration(0);
    });
  };

  useEffect(() => {
    if (!user) return;
    setSound();
    setReady(false);
    setDuration(0);
    if (sentence.id) loadSound();
  },[user,sentence && sentence.id]);

  const onLoad = () => {
    setReady(true);
  };

  const playPause = e => {
    e?.currentTarget?.blur?.();
    if (playing) 
      player.pause();
    else 
      player.play();
    
  };

  const localSetPlaying = p => {
    setPlaying(p);
    setTimeout(() => setPlayed(true),1000);
  };

  return (
    <Grid container>
      <Grid sm={3} xs={12} item className={classes.label}>
        {(sentence && sentence.emotion) ? (
          <Tooltip title="How to read this utterance" arrow>
            <span>Target Speaker</span>
          </Tooltip>
        ) : `Target Speaker`}

        <Button onClick={playPause} color="primary" className={classes.mic} style={{ display: `${sentence && sentence.corpus ? `inline-flex` : `none`}` }} disabled={sound === null || !ready}>

          {(targetLoading) ? (<CircularProgress className={classes.loadingMic} />)

            : (playing ? (<StopIcon className={classes.micIcon} />) : (<PlayArrowIcon className={classes.micIcon} />))}
        </Button>
      </Grid>
      <Grid sm={9} xs={12} item>
        <WaveSurfer
          id="prerec"
          audio={sound}
          setPlayer={setPlayer}
          setPlaying={localSetPlaying}
          setDuration={setDuration}
          colorWave="blue"
          colorProgress="#000099"
          onLoad={onLoad}
          height={50}
          trim/>
      </Grid>
    </Grid>

  );
}
