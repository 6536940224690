/* eslint-disable object-curly-newline */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { DynamoDBClient } from '@aws-sdk/client-dynamodb';
import { DynamoDBDocumentClient,UpdateCommand,PutCommand,GetCommand,QueryCommand } from '@aws-sdk/lib-dynamodb';
import { Auth } from 'aws-amplify';
import sha256 from 'crypto-js/sha256';
import config from './config';

const marshallOptions = {
  // Whether to automatically convert empty strings, blobs, and sets to `null`.
  convertEmptyValues: false, // false, by default.
  // Whether to remove undefined values while marshalling.
  removeUndefinedValues: false, // false, by default.
  // Whether to convert typeof object to map attribute.
  convertClassInstanceToMap: false, // false, by default.
};

const unmarshallOptions = {
  // Whether to return numbers as a string instead of converting them to native JavaScript numbers.
  wrapNumbers: false, // false, by default.
};

const translateConfig = { marshallOptions,unmarshallOptions };

let dynamodbClient;
let timeout;
const TIMEOUT_CONN = 10 * 1000; // 10 sec

let credentialsBuffer;
export const getCredentials = async() => {
  if (!credentialsBuffer || Date.now() > timeout + TIMEOUT_CONN) {
    timeout = Date.now();
    await Auth.currentAuthenticatedUser();
    credentialsBuffer = await Auth.currentCredentials();
  }
  return credentialsBuffer;
};

const getDynamoDB = async() => {
  const credentials = await getCredentials();
  const dynamodb = new DynamoDBClient({
    region: config.region,
    credentials,
  });
  dynamodbClient = DynamoDBDocumentClient.from(dynamodb,translateConfig);
  return dynamodbClient;
};

export const getLocalMetadata = () => {
  const groupId = window.localStorage.getItem(`groupId`);
  const d = JSON.parse(localStorage.getItem(`metadata_${groupId}`));
  return d || {};
};

export const storeMetadata = async data => {
  const groupId = window.localStorage.getItem(`groupId`);

  const db = await getDynamoDB();

  const params = {
    TableName: config.tableMetausers,
    Key: { userHash: data.hash,groupId },
    UpdateExpression:
      `SET firstname = :firstname, lastname = :lastname, gender = :gender, age = :age, accent = :accent`,
    ExpressionAttributeValues: {
      ':firstname': data.firstname,
      ':lastname': data.lastname || ``,
      ':gender': data.gender || ``,
      ':age': data.age || ``,
      ':accent': data.accent || ``,
    },
  };

  const response = await db.send(new UpdateCommand(params));

  return response;
};

export const createOrLoadMetadata = async d => {
  const data = { ...d };
  const groupId = window.localStorage.getItem(`groupId`);

  const arr = [
    data.groupId,
    data.firstname.toLowerCase(),
    data.lastname.toLowerCase(),
    data.gender,
    data.age,
    data.accent,
  ];
  data.hash = sha256(arr.join(`-`)).toString();

  const metadata = getLocalMetadata();

  // Do a for loop if we allow local storage for many, or load from DynamoDB
  localStorage.setItem(`metadata_${groupId}`,JSON.stringify({ ...data }));
  if (metadata.hash !== data.hash) 
    await storeMetadata(data);
  
};

export const readRecordedSentences = async() => {
  console.time(`recordedUtt`);
  const db = await getDynamoDB();
  const { hash } = getLocalMetadata();

  const recordings = [];

  let ExclusiveStartKey;
  do {
    const data = await db.send(
      new QueryCommand({
        TableName: `recorded_utts`,
        ProjectionExpression: `promptId, phrase, #status, s3Key`,
        IndexName: `userHash-timeStamp-index`,
        KeyConditionExpression: `userHash = :currentUser`,
        ExpressionAttributeValues: {
          ':currentUser': hash,
        },
        ExpressionAttributeNames: {
          '#status': `status`,
        },
        ScanIndexForward: false,
        ExclusiveStartKey,
      }),
    );

    ExclusiveStartKey = data.LastEvaluatedKey;

    for (const item of data.Items) {
      const { s3Key,...remaining } = item;
      recordings.push({
        ...remaining,
        s3audio: s3Key,
      });
    }
  } while (ExclusiveStartKey);

  console.timeEnd(`recordedUtt`);
  return recordings;
};

export const readGroup = async groupId => {
  const db = await getDynamoDB();

  const params = {
    TableName: config.tableGroups,
    Key: {
      groupId,
    },
    ProjectionExpression:
      `groupId, corpus, blockShuffle, shuffle, totalUtterances, forceTarget, countdown, firstname, lastname, hideMeta, recognizerError, targetSpeaker`,
  };
  const data = await db.send(new GetCommand(params));
  return data.Item;
};

export const getUtterances = async(corpus,isTargetSpeaker) => {
  console.time(`query-${corpus}`);

  const db = await getDynamoDB();
  const { hash,gender } = getLocalMetadata();

  let FilterExpression = `attribute_not_exists(${gender.toLowerCase()}TargetSpeakers) or not contains(${gender.toLowerCase()}TargetSpeakers, :currentUser)`;
  const ExpressionAttributeValues = {
    ':corpus': corpus,
    ':currentUser': hash,
  };

  if (!isTargetSpeaker) {
    FilterExpression = `(attribute_not_exists(${gender.toLowerCase()}SourceSpeakers) or (size(${gender.toLowerCase()}SourceSpeakers) < :tr and not contains(${gender.toLowerCase()}SourceSpeakers, :currentUser))) and size(${gender.toLowerCase()}TargetSpeakers) >= :tt `;
    ExpressionAttributeValues[`:tr`] = 30;
    ExpressionAttributeValues[`:tt`] = 1;
  }

  const items = [];
  let ExclusiveStartKey;

  do {
    const data = await db.send(
      new QueryCommand({
        TableName: `utterances`,
        ProjectionExpression: `id, phrase`,
        KeyConditionExpression: `corpus = :corpus`,
        FilterExpression,
        ExpressionAttributeValues,
        ExclusiveStartKey,
      }),
    );

    // console.log(data.Count);
    ExclusiveStartKey = data.LastEvaluatedKey;

    if (data.Items.length) 
      items.push(...data.Items.map(i => ({ ...i,corpus })));
    
  } while (ExclusiveStartKey);

  console.timeEnd(`query-${corpus}`);

  return items;
};

export const updateReadSentence = async(duration,targetDuration,totalRead) => {
  const db = await getDynamoDB();
  const { hash } = getLocalMetadata();
  const groupId = window.localStorage.getItem(`groupId`);

  const params = {
    TableName: config.tableMetausers,
    Key: { userHash: hash,groupId },
    UpdateExpression:
      `SET totalRead = :totalRead, totalTime = if_not_exists(totalTime, :zero) + :totalTime, totalTargetTime = if_not_exists(totalTargetTime, :zero) + :totalTargetTime`,

    ExpressionAttributeValues: {
      ':totalRead': totalRead + 1,
      ':zero': 0,
      ':totalTime': duration,
      ':totalTargetTime': targetDuration,
    },
    ReturnValues: `ALL_NEW`,
  };

  const data = await db.send(new UpdateCommand(params));
  return data.Attributes;
};

export const updateUtterance = async(r,s3Key,isTargetSpeaker) => {
  console.log(`recorded`,r);
  console.log(s3Key);

  if (!r.sentence) 
    // background testing
    return;
  

  const { status,sentence } = r;
  const { corpus,id,phrase } = sentence;

  const db = await getDynamoDB();
  const { hash,gender,accent,firstname,lastname } = getLocalMetadata();
  const groupId = window.localStorage.getItem(`groupId`);

  await db.send(
    new PutCommand({
      TableName: `recorded_utts`,
      Item: {
        userHash: hash,
        corpusIdStatus: `${id}#${status}`,
        groupId,
        accent,
        corpus,
        utteranceId: id.split(`-`)[1],
        promptId: id,
        gender,
        status,
        phrase,
        isTargetSpeaker,
        timeStamp: new Date().toISOString(),
        s3Key,
        fullName: `${firstname}_${lastname}`,
      },
    }),
  );
};

export const updateFailedUtterances = async failedUtterances => {
  const db = await getDynamoDB();
  const { hash } = getLocalMetadata();
  const groupId = window.localStorage.getItem(`groupId`);

  const shorted = failedUtterances ? Array.from(failedUtterances).join(`|`) : null;

  const params = {
    TableName: config.tableMetausers,
    Key: { userHash: hash,groupId },
    UpdateExpression: `SET failedUtterances = :failedUtterances`,

    ExpressionAttributeValues: {
      ':failedUtterances': shorted,
    },
    ReturnValues: `ALL_NEW`,
  };

  const data = await db.send(new UpdateCommand(params));
  return data.Attributes;
};

export const updateStarredUtterances = async starredUtterances => {
  const db = await getDynamoDB();
  const { hash } = getLocalMetadata();
  const groupId = window.localStorage.getItem(`groupId`);

  const shorted = starredUtterances ? starredUtterances.join(`|`) : null;

  const params = {
    TableName: config.tableMetausers,
    Key: { userHash: hash,groupId },
    UpdateExpression: `SET starredUtterances = :starredUtterances`,

    ExpressionAttributeValues: {
      ':starredUtterances': shorted,
    },
    ReturnValues: `ALL_NEW`,
  };

  const data = await db.send(new UpdateCommand(params));
  return data.Attributes;
};

export const acceptTermsOfService = async() => {
  const db = await getDynamoDB();
  const { hash } = getLocalMetadata();
  const groupId = window.localStorage.getItem(`groupId`);

  const params = {
    TableName: config.tableMetausers,
    Key: { userHash: hash,groupId },
    UpdateExpression: `SET tos = :tos`,

    ExpressionAttributeValues: {
      ':tos': true,
    },
    ReturnValues: `ALL_NEW`,
  };

  const data = await db.send(new UpdateCommand(params));
  return data.Attributes;
};

export const readMetauser = async() => {
  const db = await getDynamoDB();
  const { hash } = getLocalMetadata();
  const groupId = window.localStorage.getItem(`groupId`);
  const params = {
    TableName: config.tableMetausers,
    Key: { userHash: hash,groupId },
  };

  const data = await db.send(new GetCommand(params));
  return data.Item || getLocalMetadata();
};

export const log = async(code,err) => {
  const { hash } = getLocalMetadata();
  const groupId = window.localStorage.getItem(`groupId`);

  const db = await getDynamoDB();

  const params = {
    TableName: config.tableLogs,
    Item: {
      code,
      time: Date.now(),
      hash,
      groupId,
      err,
    },
  };

  return db.send(new PutCommand(params));
};

/** Local storage only */

export const readLocalSession = () => {
  const { hash } = getLocalMetadata();
  return localStorage.getItem(`${hash}-sessionId`) || ``;
};

export const setLocalSession = sessionId => {
  const { hash } = getLocalMetadata();
  localStorage.setItem(`${hash}-sessionId`,sessionId);
};
