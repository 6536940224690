import { useCallback,useEffect,useState } from 'react';
import Amplify,{ Auth } from 'aws-amplify';
import sha256 from 'crypto-js/sha256';
import awsconfig from './aws-exports';
import { readGroup } from './db';
import sentry from './sentry';

Amplify.configure(awsconfig);

export const useAuth = () => {
  const [user,setUser] = useState();
  const [signing,setSigning] = useState();
  const [loading,setLoading] = useState(true);
  const [group,setGroup] = useState();
  const [error,setError] = useState();

  const setUserLoadGroup = useCallback(username => {
    readGroup(username).then(g => {
      setGroup(g);
      setUser({ username });
    }).catch(err => {
      setLoading(false);
      sentry(err);
      setError(`Can't load group info, contact us and report this error.`);
    });
  },[setGroup,setUser,setLoading,setError]);

  useEffect(() => {
    if (!user) {
      setError(false);
      setLoading(true);
      Auth.currentAuthenticatedUser().then(u => {
        if (u) setUserLoadGroup(u.username); 
      }).catch(err => {
        setLoading(false);
        // sentry(err);
        // setError(err.message);
      });
    }
  },[user]);

  useEffect(() => {
    if (group) 
      setLoading(false);
    
  },[group]);

  const signIn = useCallback(async groupId => {
    setError(false);
    setSigning(true);
    window.localStorage.setItem(`groupId`,groupId);

    try {
      const password = sha256(groupId).toString();
      let u = await Auth.signIn(
        groupId,
        password,
      );

      if (u.challengeName === `NEW_PASSWORD_REQUIRED`) 
        u = await Auth.completeNewPassword(u,password);
      
      if (u) {
        setLoading(true);
        setUserLoadGroup(u.username);

        setSigning(false);
      } else 
        setError(`Group ID doesn't exist`);
      
      return u;
    } catch (err) {
      if (err.code === `UserNotFoundException`) 
        setError(`Group ID doesn't exist`);
      else {
        sentry(`error signing up:`,err);
        setError(err.message);
      }
      setSigning(false);
    }
  },[setLoading,setUserLoadGroup,setSigning]);

  const signOut = useCallback(cb => {
    setError(false);
    setLoading(true);
    setUser();
    window.localStorage.removeItem(`groupId`);
    Auth.signOut().then(() => {
      setGroup();
      cb && cb();
      setLoading(false);
    }).catch(err => setError(err.message));
  },[setUser,setGroup]);

  return {
    user,signIn,signOut,signing,loading,error,group,
  };
};

export const getAuth = () => ({ groupId: window.localStorage.getItem(`groupId`) });
