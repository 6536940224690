import React,{ useContext,useEffect,useRef,useState } from 'react';
import { Button,makeStyles } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import MicIcon from '@material-ui/icons/MicNone';
import WaveSurfer from '../../components/WaveSurfer';
import Context from '../../Context';
import { Recorder } from '../../audio';
import { getRecordedFile } from '../../storage';

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(3,0,2),
    width: `100%`,
    textAlign: `center`,
    [theme.breakpoints.down(`sm`)]: { margin: theme.spacing(1,0,1) },
  },
  mic: {
    margin: `20px`,
    borderRadius: `50%`,
    width: `80px`,
    height: `80px`,
  },
  timer: {
    display: `inline-flex`,
    width: `132px`,
  },
  countdown: {
    fontSize: `30px`,
    color: `black`,
  },
  prepare: {
    fontSize: `18px`,
    lineHeight: `65px`,
    height: `65px`,
    color: `#FF4444`,
    fontWeight: `700`,
  },
}));

const COUNTDOWN_START = 2800;
const COUNTDOWN_STEP = 400;
const COUNTDOWN_TIMER = 100;
const COUNTDOWN_DIV = 1000;

export default withSnackbar(({
  enqueueSnackbar,goNext,setSendAudio,sentence,rawAudio,setRawAudio,s3audio,targetDuration,searching,forceTarget,
}) => {
  const classes = useStyles();
  const [trimAudio,setTrimAudio] = useState();
  const [currentAudio,setCurrentAudio] = useState(null);
  const [recording,setRecording] = useState(false);
  const [player,setPlayer] = useState();
  const [playing,setPlaying] = useState(false);
  const [timer,setTimer] = useState(0);
  const [countdown,setCountdown] = useState();
  const [preparing,setPreparing] = useState();
  const timercall = useRef();
  const countdowncall = useRef();
  const recorder = useRef();
  const { deviceId } = useContext(Context);

  useEffect(() => {
    if (rawAudio) setCurrentAudio(URL.createObjectURL(rawAudio));
    else setCurrentAudio(null);
  },[trimAudio,sentence]);

  useEffect(() => {
    if (s3audio && !recording && !playing) {
      console.log(`s3audio`,s3audio);
      getRecordedFile(s3audio).then(blob => {
        setRawAudio(blob);
        setCurrentAudio(URL.createObjectURL(blob));
      });
    }
  },[s3audio,setRawAudio]);

  const errHandler = e => {
    console.log(e);
    setPreparing(false);
  };

  // Counter
  useEffect(() => {
    if (countdown === COUNTDOWN_STEP) {
      recorder.current.start().then(() => {
        setRecording(true);
      })
        .catch(err => errHandler(err));
      let t = 0;
      timercall.current = setInterval(() => {
        setTimer(++t);
      },
      100);
    } else if (countdown === 0) {
      setPreparing(false);
      clearInterval(countdowncall.current);
    }
  },[countdown]);

  const record = e => {
    e?.currentTarget?.blur?.();
    setPreparing(true);
    timercall.current && clearInterval(timercall.current);
    if (recording) {
      // Stop recording
      timercall.current = null;
      recorder.current.stop().then(({ blob }) => {
        setRecording(false);
        setPreparing(false);
        setRawAudio(blob);
        setTrimAudio(blob);
        recorder.current.release();
        recorder.current = null;
      }).catch(e => errHandler(e));
    } else {
      // Start recording
      setTimer(0);
      setCountdown(COUNTDOWN_START);
      if (recorder.current) 
        try {
          recorder.current.stop().then(() => recorder.current.release());
        } catch {
          console.log(`no current`);
        }
      
      const rc = new Recorder();
      recorder.current = rc;

      rc.init(deviceId).then(() => {
        let t = COUNTDOWN_START;
        countdowncall.current = setInterval(() => {
          t -= COUNTDOWN_STEP;
          setCountdown(t);
        },COUNTDOWN_TIMER);
      }).catch(err => {
        errHandler(err);
      });
    }
  };

  const play = e => {
    e?.currentTarget?.blur?.();
    if (playing) 
      player.stop();
    else 
      player.play();
    
  };

  const reset = e => {
    e?.currentTarget?.blur?.();
    setCurrentAudio(null);
    setRawAudio(null);
    setTrimAudio(null);
    setPlaying(false);
  };

  const submit = async e => {
    e?.currentTarget?.blur?.();
    const duration = player.getDuration();
    setSendAudio({ rawAudio,trimAudio,duration,sentence,targetDuration });
    reset();
    goNext();
  };

  const next = async e => {
    e?.currentTarget?.blur?.();
    if (recording || currentAudio) 
      if (!window.confirm(`Are you sure you want to skip this recording?`)) return;
    
    reset();
    goNext();
  };

  const trimCallback = audio => {
    setTrimAudio(audio);
  };

  const getTimer = () => {
    const int = Math.floor(timer / 10);
    const p = timer % 10;
    return `${int}.${p} s`;
  };

  // Listen to keyboard
  const keyAction = event => {
    if (forceTarget || searching || preparing || !(sentence && sentence.phrase)) return; 
    switch (event.keyCode) {
      case 32: // spacebar
        if (currentAudio) 
          play();
        else 
          record();
        
        break;
      case 82: // letter R
        reset();
        break;
      case 78: // letter N
        if (forceTarget || preparing || !currentAudio || sentence.finished) 
          break;
        
        submit();
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    document.addEventListener(`keydown`,keyAction,false);
    return () => document.removeEventListener(`keydown`,keyAction,false);
  });

  return (
    <div className={classes.submit}>
      <Button variant="contained" onClick={reset} color="primary" disabled={forceTarget || !currentAudio || !(sentence && sentence.phrase)}>
        Retry
      </Button>

      <Button variant="contained" onClick={play} color="secondary" className={classes.mic} style={{ display: `${currentAudio ? `inline-flex` : `none`}` }} disabled={forceTarget || preparing}>
        {playing ? <StopIcon fontSize="large" /> : <PlayArrowIcon fontSize="large" />}
      </Button>

      <Button variant="contained" onClick={record} color="secondary" disabled={forceTarget || preparing || !(sentence && sentence.phrase)} className={classes.mic} style={{ display: `${!currentAudio ? `inline-flex` : `none`}` }}>
        {preparing ? (
          <span className={classes.countdown}>
            {Math.ceil(countdown / COUNTDOWN_DIV)}
          </span>
        ) : (recording ? <StopIcon fontSize="large" /> : <MicIcon fontSize="large" />)}
      </Button>

      {!preparing && recording ? <div className={classes.timer}>{getTimer()}</div> : (
        <Button variant="contained" onClick={submit} color="primary" disabled={forceTarget || preparing || !currentAudio || sentence.finished}>
          Submit
        </Button>
      )}

      <Button style={{ marginLeft: 10 }} onClick={next} color="default" disabled={sentence && sentence.finished}>
        Skip
      </Button>
      <div className={classes.prepare}>
        {forceTarget && !preparing && `Play Target Speaker before recording`}
        {preparing ? `Wait to Record` : (
          <WaveSurfer
            audio={currentAudio}
            setPlayer={setPlayer}
            setPlaying={setPlaying}
            setRawAudio={setRawAudio}
            id="micAudio"
            micMode={recording}
            trimCallback={trimCallback}/>
        )}
      </div>

    </div>
  );
});
