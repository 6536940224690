import React,{
  useCallback,
  useContext,useEffect,useRef,useState,
} from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import Toolbar from './Toolbar';
import { readMetauser,readRecordedSentences } from '../../db';
import SideBar from './SideBar';
import Authenticated from '../../_authenticated';
import Context from '../../Context';
import Prerecorded from './Prerecorded';
import Reader from '../../reader';
import sentry from '../../sentry';
import UtteranceBox from './UtteranceBox';
import CountdownAlert from './CountdownAlert';
import { RecordingInfo } from './RecordingInfo';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down(`md`)]: {
      maxWidth: `100%`,
      paddingRight: `250px`,
    },
    [theme.breakpoints.down(`sm`)]: { marginTop: `50px` },
    [theme.breakpoints.down(`xs`)]: { paddingRight: `16px` },
  },
  prerec: {
    margin: `5px 50px`,
    [theme.breakpoints.down(`sm`)]: { margin: `5px` },
  },
  readTitle: {
    fontStyle: `italic`,
    [theme.breakpoints.down(`sm`)]: { fontSize: `20px` },
  },
}));

export default withSnackbar(({ enqueueSnackbar }) => {
  const classes = useStyles();
  // Context group var and app loading status
  const { group,loading,user } = useContext(Context);

  // Set current metauser information from DynamoDB
  const [meta,setMeta] = useState();

  // Set current accepted utterances
  const [recordings,setRecordings] = useState(null);

  // Store current sentence
  const [sentence,setSentence] = useState({ });

  // And current recorded rawAudio (blob format)
  const [rawAudio,setRawAudio] = useState();
  const [s3audio,setS3Audio] = useState();
  const [status,setStatus] = useState();

  // Set if there will be no next sentence to read, a response from dynamoDB querying sentences
  const [last,setLast] = useState(false);

  // Send recording to queue bar
  const [sendAudio,setSendAudio] = useState();

  // Have a local queue for failed recordings, to use after all sentences are read.
  const [queue,setQueue] = useState([]);

  // Load next phrase to speed up usability
  const preloadData = useRef();

  // Put current audio in buffer if some other replaces it
  const bufferLoaded = useRef();

  // store a constant about sentence reader
  const reader = useRef();

  // Store all recordings
  const recordingsSafe = useRef([]);

  // Target duration
  const [targetDuration,setTargetDuration] = useState(0);

  const [targetPlayed,setTargetPlayed] = useState(false);

  const [notSupported,setNotSupported] = useState(false);

  const [searching,setSearching] = useState(false);

  const [toRecord,setToRecord] = useState(null);

  const preLoad = useCallback(() => {
    if (preloadData.current || last) return;
    const ans = reader.current(recordingsSafe);
    // Check if this is random and already recorded
    preloadData.current = ans;
  },[reader,last]);

  const nextPhrase = useCallback(() => {
    if (preloadData.current) {
      setSentence(preloadData.current.sentence);
      if (!preloadData.current.lastId) {
        preloadData.current = null;
        setLast(true);
      } else {
        preloadData.current = null;
        preLoad();
      }
      return;
    }
    try {
      const ans = reader.current(recordingsSafe);
      setSentence(ans.sentence);
      if (!ans.lastId) 
        setLast(true);
      else 
        preLoad();
      
    } catch (err) {
      sentry(err);
      setSentence({ phrase: `Error` });
      enqueueSnackbar(`Cant load more utterances, please reload the application`,{ variant: `error`,autoHideDuration: 10000 });
    }
  },[preloadData,setSentence,preLoad,enqueueSnackbar]);

  useEffect(() => {
    if (!loading && user && group) {
      if (MediaRecorder.notSupported) {
        enqueueSnackbar(`Your browser may not support mic capturing, please try another browser`,{ variant: `error`,persist: true });
        setNotSupported(true);
      }
      readMetauser().then(u => {
        setMeta(u);
        setTimeout(() => {
          if (!reader.current) 
            enqueueSnackbar(`This first load may take some seconds, please wait`,{ variant: `info` });
          
        },3000);
        readRecordedSentences().then(resp => {
          setRecordings(resp);
          Reader(group,resp,setToRecord).then(fn => {
            reader.current = fn;
            nextPhrase();
          });
        }).catch(() => {
          Reader(group,[]).then(fn => {
            reader.current = fn;
            nextPhrase();
          });
        });
      }).catch(sentry);
    }
  },[loading,user,group]);

  const setRecording = rec => {
    if (queue.length || sentence.id === rec.sentence.id) 
      bufferLoaded.current = null;
    else 
      bufferLoaded.current = { rawAudio,sentence };
    
    setRawAudio(rec.rawAudio || null);
    setSentence(rec.sentence);
    setS3Audio(rec.s3audio || null);
    setStatus(rec.status);
  };

  const goNext = () => {
    setStatus(null);
    if (queue.length) {
      const q = [...queue];
      const rec = q.shift();
      setQueue(q);
      setRecording(rec);
    } else if (bufferLoaded.current) {
      setRawAudio(bufferLoaded.current.rawAudio);
      setSentence(bufferLoaded.current.sentence);
      setS3Audio(null);
      bufferLoaded.current = null;
    } else if (!last) {
      setSentence({ loading: true });
      nextPhrase();
    } else {
      setSentence({ finished: true });
      enqueueSnackbar(`You completed all utterances!`,{ variant: `success` });
    }
  };

  return (
    <Authenticated>
      <div>
        <SideBar
          queue={queue}
          currentSentence={sentence}
          sendAudio={sendAudio}
          setSendAudio={setSendAudio}
          meta={meta}
          dbRecordings={recordings}
          setMeta={setMeta}
          group={group}
          setRecording={setRecording}
          setSearching={setSearching}
          recordingsSafe={recordingsSafe}
          toRecord={toRecord}/>
        <Container maxWidth="md" className={classes.container}>

          <Box my={4}>
            <Typography variant="h5" component="h1" align="center" gutterBottom className={classes.readTitle}>
              Read the sentences:
            </Typography>

            <CountdownAlert group={group} meta={meta} />

            <UtteranceBox sentence={sentence} status={status} />

            <RecordingInfo sentence={sentence}/>

            <div className={classes.prerec}>
              <Prerecorded
                sentence={sentence}
                setDuration={setTargetDuration}
                setPlayed={setTargetPlayed}
                goNext={goNext}
                meta={meta}
                preloadSentence={preloadData.current && preloadData.current.sentence}/>
            </div>

            {!notSupported && (
              <Toolbar
                goNext={goNext}
                setSendAudio={setSendAudio}
                disableNext={last && queue.length === 0}
                forceTarget={group && group.forceTarget && !targetPlayed && (!s3audio && !rawAudio)}
                sentence={sentence}
                rawAudio={rawAudio}
                setRawAudio={setRawAudio}
                targetDuration={targetDuration}
                s3audio={s3audio}
                searching={searching}/>
            )}

          </Box>
        </Container>
      </div>

    </Authenticated>
  );
});
