/* eslint-disable react/jsx-props-no-spreading */
import React,{ useContext,useEffect,useState } from 'react';
import { FormControl,Grid,InputLabel,makeStyles,Select } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useNavigate } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Controller,useForm } from 'react-hook-form';
import Context from '../Context';
import { createOrLoadMetadata,getLocalMetadata } from '../db';

const useStyles = makeStyles(theme => ({
  form: {
    width: `100%`, // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3,0,2),
    width: `100%`,
    textAlign: `center`,
  },
  selectbox: { width: `100%` },
}));

const ACCENTS = [
  `Adi`,
  `American`,
  `Apatani`,
  `Assamese`,
  `Bengali (Bangla)`,
  `Bodo`,
  `Dogri`,
  `Galo`,
  `Garo`,
  `Gujarati`,
  `Hindi`,
  `Kannada`,
  `Karbi`,
  `Kashmiri`,
  `Khasi`,
  `Kokborok`,
  `Konkani`,
  `Maithili`,
  `Malayalam`,
  `Marathi`,
  `Meitei (Manipuri)`,
  `Mizo`,
  `Nagamese`,
  `Nepali`,
  `Nyishi`,
  `Odia`,
  `Other`,
  `Punjabi`,
  `Sanskrit`,
  `Santali`,
  `Sindhi`,
  `Tamil`,
  `Telugu`,
  `Urdu`,
];

export default function MetadataForm() {
  const classes = useStyles();
  const [sending,setSenting] = useState();
  const [userId,setUserId] = useState();
  const { group } = useContext(Context);
  const navigate = useNavigate();
  const { register,handleSubmit,reset,watch,control,formState: { errors } } = useForm();

  const onSubmit = d => {
    setSenting(true);
    const data = { ...d };
    if (userId) {
      data.firstname = userId;
      data.lastname = userId;
    }
    createOrLoadMetadata({ ...data,groupId: group.groupId }).then(r => {
      navigate(`/instructions`);
    });
    // console.log(data);
  };

  useEffect(() => {
    const metadata = getLocalMetadata();
    reset(metadata);
  },[]);

  // useEffect(() => {
  //   console.log('user changed', user);
  // }, [user]);

  return (
    <form id="metadata-form" className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {userId ? (
          <Grid item sm={12} xs={12}>
            <Typography>
              User ID:
              {` `}
              { userId }
            </Typography>
          </Grid>
        ) : (
          <>

            <Grid item sm={6} xs={12}>
              <Controller
                name="firstname"
                control={control}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={group && group.firstname ? group.firstname : `First Name`}
                    autoComplete={group && group.firstname ? group.firstname : `firstname`}
                    autoFocus
                    {...field}/>
                )}/>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Controller
                name="lastname"
                control={control}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={group && group.lastname ? group.lastname : `Last Name`}
                    autoComplete={group && group.lastname ? group.lastname : `lastname`}
                    {...field}/>
                )}/>
            </Grid>
          </>
        )}
        {!(group && group.hideMeta) && (
          <>
            <Grid item sm={4} xs={12}>
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <FormControl className={classes.selectbox}>
                    <InputLabel htmlFor="gender">Gender</InputLabel>
                    <Select
                      native
                      required
                      inputProps={{
                        name: `gender`,
                        id: `gender`,
                      }}
                      {...field}>
                      <option aria-label="None" value="" />
                      <option>Male</option>
                      <option>Female</option>
                    </Select>
                  </FormControl>
                )}/>
            </Grid>

            <Grid item sm={4} xs={12}>
              <Controller
                name="age"
                control={control}
                render={({ field }) => (
                  <FormControl className={classes.selectbox}>
                    <InputLabel htmlFor="age">Age</InputLabel>
                    <Select
                      native
                      required
                      inputProps={{
                        name: `age`,
                        id: `age`,
                      }}
                      {...field}>
                      <option aria-label="None" value="" />
                      <option>18-30</option>
                      <option>30-50</option>
                      <option>50+</option>
                    </Select>
                  </FormControl>
                )}/>
            </Grid>

            <Grid item sm={4} xs={12}>
              <Controller
                name="accent"
                control={control}
                render={({ field }) => (
                  <FormControl className={classes.selectbox}>
                    <InputLabel htmlFor="accent">Dialect</InputLabel>
                    <Select
                      native
                      required
                      inputProps={{
                        name: `accent`,
                        id: `accent`,
                      }}
                      {...field}>
                      <option aria-label="None" value="" />
                      {ACCENTS.map(accent => (<option key={accent} value={accent.split(` `)[0]}>{accent}</option>))}
                    </Select>
                  </FormControl>
                )}/>
            </Grid>
          </>
        )}
      </Grid>

      <div className={classes.submit}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={sending}
          id="metadata-next">
          Next
        </Button>
      </div>
    </form>
  );
}
