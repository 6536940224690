import React,{
  useCallback,
  useEffect,useRef,useState,
} from 'react';
import {
  Divider,ListItem,ListItemIcon,ListItemText,makeStyles,Popover,TextField,
} from '@material-ui/core';

import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import SearchIcon from '@material-ui/icons/Search';

import StatusIcon from '../StatusIcon';

const useStyles = makeStyles(() => ({
  sentence: {
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    display: `-webkit-box`,
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': `vertical`,
    maxHeight: `72px`,
    cursor: `pointer`,
  },
  searchIcon: {
    right: `5px`,
    position: `absolute`,
    top: `5px`,
  },
  starFilter: {
    right: `28px`,
    position: `absolute`,
    top: `5px`,
  },
  searchBox: {
    width: `100%`,
    padding: `5px`,
  },
  listRow: { cursor: `default` },
  starIcon: {
    minWidth: `30px`,
    cursor: `pointer`,
  },
  listIcon: {
    minWidth: `33px`,
    cursor: `default`,
  },
}));

export default function FileList({ recordings,setSearching,setRecording,group }) {
  const classes = useStyles();
  const [rows,setRows] = useState([]);
  const [query,setQuery] = useState(``);
  const [anchorEl,setAnchorEl] = useState(null);
  const windowList = useRef();

  const handleSearch = event => {
    setSearching(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSearching(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    const nr = query
      ? recordings.filter(r => r.sentence.phrase?.toLowerCase().includes(query.toLowerCase()))
      : recordings;
    setRows(nr);
  },[recordings,query]);

  const onSearch = useCallback(e => {
    setQuery(e.target.value);
  },[]);

  const recClick = rec => () => {
    if (!group?.targetSpeaker) 
      return;
    
    setRecording(rec);
    // updateRecord(rec, 'uploading');
    // runRecording(rec);
  };

  const currentRows = rows;

  const Row = ({ index,style }) => {
    const rec = currentRows[index];
    return (
      <ListItem style={style} className={classes.listRow} button key={rec.sentence.phrase}>
        <ListItemIcon className={classes.listIcon}><StatusIcon status={rec.status} /></ListItemIcon>
        <ListItemText className={classes.sentence} primary={rec.sentence.phrase} onClick={recClick(rec)} />
      </ListItem>
    );
  };

  return (
    <>
      <SearchIcon className={classes.searchIcon} onClick={handleSearch} />
      <Popover
        id="recordings-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: `top`,
          horizontal: `center`,
        }}
        transformOrigin={{
          vertical: `bottom`,
          horizontal: `center`,
        }}>
        <div className={classes.searchBox}>
          <TextField id="search-basic" label="Search" onChange={onSearch} variant="outlined" autoFocus />
        </div>
      </Popover>
      <Divider />
      <AutoSizer>
        {({ height,width }) => (
          <List
            className="List"
            height={height - 84}
            itemCount={currentRows.length}
            itemSize={100}
            width={width}
            ref={windowList}>
            {Row}
          </List>
        )}
      </AutoSizer>
    </>
  );
}
