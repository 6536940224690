import { useSnackbar } from 'notistack';
import React,{ useCallback,useContext,useEffect,useRef,useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { getConstraints } from '../../audio';
import Context from '../../Context';
import MicrophonePlugin from './MicrophonePlugin';
import { trimAudio,bufferToWave } from './utils';

let _id = 0;
const nextId = () => {
  _id += 1;
  return `wsid-${_id}`;
};

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export default function ClientWaveSurfer({
  id,audio,trimCallback,setRawAudio,onLoad,setPlayer,setPlaying,colorWave,colorProgress,height,micMode,onMicReady,trim,setDuration,forceReload,
}) {
  const [constraints,setConstraints] = useState();
  const { deviceId } = useContext(Context);
  const currentDevice = useRef();
  const wavesurfer = useRef();
  const trimmedRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!micMode || deviceId === currentDevice.current) return;
    getConstraints(deviceId).then(ct => {
      currentDevice.current = deviceId;
      setConstraints(ct);
    }).catch(err => {
      console.error(err);
      enqueueSnackbar(`Error loading your audio devices, please restart your browser or OS`,{ variant: `error` });
    });
  },[deviceId,micMode]);

  const wsReady = useCallback(() => {
    if (onLoad) onLoad();
    if (micMode) return;

    if (setDuration) 
      setDuration(wavesurfer.current.getDuration());
    
    if (setRawAudio) 
      setRawAudio(bufferToWave(wavesurfer.current.backend.buffer,0,wavesurfer.current.backend.buffer.length));
    

    if (!trimCallback || trimmedRef.current) return;
    trimmedRef.current = true;
    const peaks = wavesurfer.current.backend.getPeaks(1024);
    const region = trimAudio(peaks,wavesurfer.current.getDuration());
    const total = wavesurfer.current.backend.buffer.length;

    if (region.start === region.end) {
      // wavesurfer.current.empty();
      if (setDuration) 
        setDuration(0);
      
      return;
    }

    const buffer = bufferToWave(wavesurfer.current.backend.buffer,Math.round(region.start * total),Math.round((region.end - region.start) * total));

    console.log(`cut success`,region);

    trimCallback(buffer);
  },[audio,trimCallback,micMode,onLoad,setDuration]);

  useEffect(() => {
    console.log(`starting...`,micMode,forceReload,constraints);
    const params = {
      container: document.getElementById(id),
      waveColor: colorWave || `violet`,
      progressColor: colorProgress || `purple`,
      height: height || 65,
    };
    if (micMode) {
      if (!constraints) return;
      params.waveColor = `black`;
      params.interact = false;
      params.cursorWidth = 0;
      params.plugins = [
        MicrophonePlugin.create({
          bufferSize: 4096,
          numberOfInputChannels: 1,
          numberOfOutputChannels: 1,
          constraints,
        }),
      ];
    }
    const wc = WaveSurfer.create(params);
    wavesurfer.current = wc;

    wc.on(`ready`,wsReady);

    wc.on(`pause`,() => {
      wavesurfer.current.params.container.style.opacity = 0.7;
      setPlaying && setPlaying(false);
    });

    wc.on(`play`,() => {
      wc.params.container.style.opacity = 1;
      setPlaying && setPlaying(true);
    });

    if (micMode) {
      wc.microphone.on(`deviceReady`,() => {
        onMicReady && onMicReady();
      });
      wc.microphone.start();
    }

    setPlayer && setPlayer(wavesurfer.current);

    return (() => {
      console.log(`stopping... `,micMode,forceReload,constraints);
      try {
        wc.microphone.stop();
      } catch (err) {
        console.log(err);
      }
      wc.destroy();
    });
  },[micMode,forceReload,constraints]);

  useEffect(() => {
    if (wavesurfer.current) 
      if (audio) 
        wavesurfer.current.load(audio);
      else {
        if (wavesurfer.current.backend) wavesurfer.current.empty();
        trimmedRef.current = false;
      }
    
  },[audio]);

  if (!id) return null;

  return (<div id={id} />);
}
