import React,{
  useCallback,
  useContext,useEffect,useRef,useState,
} from 'react';
import {
  Badge,
  Button,CircularProgress,Drawer,Hidden,makeStyles,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import MenuIcon from '@material-ui/icons/Menu';
import { uploadFile } from '../../../storage';
import { updateReadSentence,updateUtterance } from '../../../db';
import Context from '../../../Context';
import Counter from './Counter';
import sentry from '../../../sentry';
import FileList from './FileList';
import { callLambdaFn } from '../../../lambdafn';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  drawer: {
    flexShrink: 0,
    width: `85%`,
    [theme.breakpoints.up(`xs`)]: { width: drawerWidth },

  },
  drawerPaper: {
    width: `85%`,
    overflowX: `hidden`,
    [theme.breakpoints.up(`xs`)]: {
      width: `240px`,
      marginTop: `60px`,
      height: `calc(100vh - 35px)`,
    },
    [theme.breakpoints.up(`md`)]: { width: `260px` },
  },
  menuButton: {
    position: `absolute`,
    right: 10,
    top: 10,
  },
  loading: {
    display: `flex`,
    alignItems: `center`,
    flexDirection: `column`,
    marginTop: `50%`,
  },
}));

const StatusSideBar = ({
  meta,setMeta,setSendAudio,sendAudio,setRecording,dbRecordings,setSearching,recordingsSafe,toRecord,
}) => {
  const { group,loading } = useContext(Context);
  const [totalUniqueErrors,setTotalUniqueErrors] = useState(0);
  const [duration,setDuration] = useState(0);
  const [targetTotal,setTargetTotal] = useState(0);
  const [recordings,setRecordings] = useState(null);

  const failedUtterances = useRef(new Set());
  const recordingsRunning = useRef(new Set());
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const [mobileOpen,setMobileOpen] = useState(false);

  const updateTotalErrors = useCallback(() => {
    setTotalUniqueErrors(failedUtterances.current.size);
  },[setTotalUniqueErrors,recordingsSafe,failedUtterances]);

  // Update current recordings with loaded keys
  const loadRecorded = keys => {
    const records = Array.from(recordingsSafe.current);
    if (!keys) return;

    keys.forEach(({ promptId,phrase,status,s3audio }) => {
      const parts = promptId.split(`-`);
      const [corpus] = parts;

      if (parts.length < 2) return;
      if (records.find(r => r.sentence.id === promptId)) return;

      records.push({
        sentence: {
          corpus,
          id: promptId,
          phrase,
        },
        s3audio,
        status,
      });

      if (status === `error`) 
        failedUtterances.current.add(promptId);
      
    });

    recordingsSafe.current = records;
    setRecordings(records);
    updateTotalErrors();
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const successTotal = (recordings || []).filter(r => r.status === `success`).length;

  const updateRecord = useCallback((rec,status) => {
    const newRec = { ...rec,status };
    const recIdx = recordingsSafe.current.findIndex(r => r.sentence.id === rec.sentence.id);
    if (status === `error`) {
      recordingsSafe.current.splice(recIdx,1);
      recordingsSafe.current.unshift(newRec);
    } else 
      recordingsSafe.current[recIdx] = newRec;
    
    setRecordings([...recordingsSafe.current]);
    updateTotalErrors();
    recordingsRunning.current.delete(rec.sentence.id);
    return newRec;
  },[setRecordings,recordingsRunning,recordingsSafe,updateTotalErrors]);

  const failRecording = useCallback((r,msg,err,key,isTargetSpeaker) => {
    enqueueSnackbar(msg,{ variant: `error`,autoHideDuration: 12000 });

    if (err) sentry(err);

    if (!key) 
      return;
    

    if (!failedUtterances.current.has(r.sentence.id)) {
      failedUtterances.current.add(r.sentence.id);
      updateUtterance({ ...r,status: `error` },key,isTargetSpeaker).then(() => {
        const trimmedR = { status: r.status,sentence: r.sentence,s3audio: key };
        updateRecord(trimmedR,`error`);
      });
    }
  },[enqueueSnackbar,updateRecord]);

  const runRecognizer = useCallback((r,key,successCB,trials = 1) => {
    callLambdaFn(r.sentence,key).then(({ success,message }) => {
      if (success) 
        successCB();
      else {
        const msg = message || `Sentence is too different from expected, skipping to next phrase.`;
        failRecording(r,`"${r.sentence.phrase}": ${msg}`,null,key,group.targetSpeaker);
      }
    }).catch(err => { // try one more time if got error / timeout
      if (trials) 
        runRecognizer(r,key,successCB,0);
      else {
        failRecording(r,`Recognizer error, please try again`,err);
        sentry(err);
      }
    });
  },[failRecording]);

  const runUploadFile = useCallback((r,successCB,trials = 1) => {
    // if head returns error, this may be good to go
    console.log(`starting upload`,r,group);

    let base;
    if (group.targetSpeaker) 
      base = `validated`;
    

    uploadFile(r.rawAudio,r.sentence,base).then(response => {
      if (group.targetSpeaker) 
        successCB(response,group.targetSpeaker);
      else 
        runRecognizer(r,response,() => {
          successCB(response,group.targetSpeaker);
        });
      
    }).catch(err => {
      if (trials) 
        runUploadFile(r,successCB,0);
      else {
        failRecording(r,`File upload for "${r.sentence.phrase}" failed, please try again.`,err);
        sentry(err);
      }
    });
  },[failRecording,runRecognizer,group]);

  const runRecording = useCallback(r => {
    if (recordingsRunning.current.has(r.sentence.id)) return;
    recordingsRunning.current.add(r.sentence.id);

    // see if this is replacing
    runUploadFile(r,(key,isTargetSpeaker) => {
      updateReadSentence(r.duration,r.targetDuration,successTotal).then(newMeta => {
        setMeta(newMeta);
        updateUtterance({ ...r,status: `success` },key,isTargetSpeaker).then(() => {
          const trimmedR = { status: r.status,sentence: r.sentence,s3audio: key };
          updateRecord(trimmedR,`success`);
        });
      }).catch(err => failRecording(r,`Couldn't update your hash data, please refresh browser and try again`,err));
    });
  },[recordingsRunning,setMeta,runUploadFile,failRecording,updateRecord,successTotal]);

  useEffect(() => {
    if (sendAudio) {
      const i = recordingsSafe.current.findIndex(rr => rr.sentence.id === sendAudio.sentence.id);
      const obj = { ...sendAudio,status: `uploading` };
      if (i >= 0) {
        // If recording already exists and it's sent or resent, it's already verified
        if (recordingsSafe.current[i].status === `success`) 
          obj.verified = true;
        
        // anyway we replace current existing recording with new obj
        recordingsSafe.current[i] = obj;
      } else 
        recordingsSafe.current.unshift(obj);
      
      setSendAudio(null);
      runRecording(obj);
      setRecordings(recordingsSafe.current);
    }
  },[sendAudio,setRecordings,setSendAudio,runRecording]);

  // Loop for updating meta if any meta obj change is detected
  useEffect(() => {
    if (meta) {
      setDuration(meta.totalTime || 0);
      setTargetTotal(meta.totalTargetTime || 0);
    }
  },[meta]);

  useEffect(() => {
    loadRecorded(dbRecordings);
  },[dbRecordings]);

  if (loading || !meta) return null;

  const drawer = (
    <>
      <Counter
        successTotal={successTotal}
        totalTime={duration}
        totalTargetTime={targetTotal}
        totalUniqueErrors={totalUniqueErrors}
        countdown={group && group.countdown}
        toRecord={toRecord}/>
      {recordings === null ? (
        <div className={classes.loading}><CircularProgress /></div>
      ) : (
        <FileList
          meta={meta}
          group={group}
          recordings={recordings}
          setSearching={setSearching}
          setRecording={setRecording}/>
      )}

    </>
  );

  return (
    <>
      <Hidden smUp implementation="css">
        <Button
          color="primary"
          variant="contained"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}>
          <Badge badgeContent={totalUniqueErrors} color="error" max={99}><MenuIcon /></Badge>
        </Button>
        <Drawer
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{ paper: classes.drawerPaper }}
          className={classes.drawer}
          ModalProps={{ keepMounted: true }}> // Better open performance on mobile.
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{ paper: classes.drawerPaper }}
          anchor="right"
          variant="permanent"
          open
          className={classes.drawer}>
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
};

export default StatusSideBar;
