import axios from 'axios';
import { LambdaClient,InvokeCommand } from '@aws-sdk/client-lambda';
import Auth from '@aws-amplify/auth';
import config from './config';
import { log,getLocalMetadata } from './db';
import sentry from './sentry';

export async function callLambdaFn(sentence,key) {
  const credentials = await Auth.currentCredentials();
  const { gender } = getLocalMetadata();

  const lambda = new LambdaClient({
    region: config.lambdaRegion,
    credentials,
  });

  const target = `${gender === `Female` ? config.lambdaPathFemale : config.lambdaPathMale}${sentence.id}.npz`;
  const body = {
    source: key,
    target,
  };

  const command = new InvokeCommand(
    {
      FunctionName: config.lambdaARN,
      Payload: JSON.stringify(body),
    },
  );

  const response = await lambda.send(command);
  // console.log('*** response', response);

  const asciiDecoder = new TextDecoder(`ascii`);
  const data = JSON.parse(asciiDecoder.decode(response.Payload));

  const success = data.statusCode === 202;
  return { success,message: data.body };
}
