import {
  GetObjectCommand,
  PutObjectCommand,
  ListObjectsCommand,
  S3,
} from '@aws-sdk/client-s3';
import { getLocalMetadata,readLocalSession,setLocalSession,getCredentials } from './db';
import { getAuth } from './auth';
import config from './config';

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );
}

function readAllChunks(readableStream,mimeType) {
  return new Promise((resolve,reject) => {
    const reader = readableStream.getReader();
    const chunks = [];

    function pump() {
      return reader.read().then(({ value,done }) => {
        if (done) 
          return resolve(new Blob(chunks,{ type: mimeType }));
        
        chunks.push(value);
        return pump();
      });
    }

    return pump();
  });
}

const getS3 = async() => {
  const credentials = await getCredentials();
  return new S3({
    region: config.region,
    credentials,
  });
};

export function getMetaPath(base = `private`) {
  const meta = getLocalMetadata();
  const { groupId } = getAuth();
  const re = /[^A-Za-z0-9]/g;

  const firstname = toTitleCase(meta.firstname ? meta.firstname.replace(re,``) : `Unnamed`);
  const lastname = toTitleCase(meta.lastname ? meta.lastname.replace(re,``) : `Unnamed`);
  return `${base}/${groupId}/${firstname}_${lastname}_${meta.accent}__${meta.hash}`;
}

export const uploadFile = async(blob,sentence,base) => {
  const meta = getLocalMetadata();

  let fileName = ``;
  let sessionId = readLocalSession();

  if (sentence) {
    fileName = sentence.id;
    if (!base) 
      fileName += `-${Date.now()}`;
    
  } else {
    sessionId = `${Date.now()}`;
    fileName = `background-${sessionId}`;
  }

  const key = `${getMetaPath(base)}/${fileName}.wav`;
  const file = new File([blob],key);

  const s3 = await getS3();

  const params = {
    Bucket: config.bucket,
    Key: key,
    Body: file,
    Metadata: {
      'x-amz-meta-firstname': encodeURIComponent(meta.firstname),
      'x-amz-meta-lastname': encodeURIComponent(meta.lastname),
      'x-amz-meta-gender': encodeURIComponent(meta.gender),
      'x-amz-meta-age': encodeURIComponent(meta.age),
      'x-amz-meta-accent': encodeURIComponent(meta.accent),
      'x-amz-meta-browser': navigator.userAgent,
    },
  };

  if (sentence) 
    params.Metadata[`x-amz-meta-sentence`] = encodeURIComponent(sentence.phrase);
  

  if (sessionId) 
    params.Metadata[`x-amz-meta-sessionid`] = sessionId;
  

  await s3.send(new PutObjectCommand(params));

  if (!sentence) 
    setLocalSession(sessionId);
  

  return key;
};

export const getRecordedFile = async key => {
  const s3 = await getS3();

  const params = {
    Bucket: config.bucket,
    Key: key,
    Download: true,
  };

  const file = await s3.send(new GetObjectCommand(params));
  return readAllChunks(file.Body,file.ContentType);
};

export const getTargetFile = async(sentence,isFemale) => {
  const key = `web-target-speaker${isFemale ? `-female` : ``}/${sentence.id}.mp3`;
  let body;
  // Try female or default, if error on female handle to load default
  try {
    body = await getRecordedFile(key);
  } catch (err) {
    console.error(`Unable to fetch target file`,err);
    throw err;
  }
  return URL.createObjectURL(body);
};
